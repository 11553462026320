.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    font-size: 18px;
}

.pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination li.active {
    color: white;
    background-color: #3f51b5;
    color: white;
    border-radius: 5px;
    height: 24px;
    width: 20px;
    cursor: pointer;
    text-align: center;
    margin-top: 0px;
    vertical-align: middle;
    padding-top: 0px;
}

.pagination li a {
    text-decoration: none;
    color: #9c27b0;
}

.pagination li a:hover {
    text-decoration: none;
    cursor: pointer;

    color: #d77fee;

}


.pagination svg {
    cursor: pointer;
    color: #9c27b0;
    margin-top: -10px;
}

.pagination svg:hover {
    color: #d77fee;
}
