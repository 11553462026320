.our-benefits-background-image {
    filter: blur(6px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    animation: our-benefits-parallaxMove 15s linear forwards;
    z-index: -1;
}

.our-benefits-features-section {
    padding: 50px 0;
}

.our-benefits-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.our-benefits-section-title {
    text-align: center;
    font-size: 36px;
    color: #333;
    margin-bottom: 30px;
}

.our-benefits-features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.our-benefits-features svg {
    height: 50px;
    width: 50px;
    color: #99227b;
}



.our-benefits-feature {
    flex: 1 1 300px;
    max-width: 300px;
    text-align: center;
    margin-bottom: 30px;
}

.our-benefits-feature-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

.our-benefits-feature-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    @extend .animatedTextWaveGradient;
}

.our-benefits-feature-description {
    font-size: 18px;
    color: #666;
    line-height: 1.6;
}

@keyframes our-benefits-parallaxMove {
    0% { transform: translateY(0); }
    100% { transform: translateY(-6%); }
}
