
.card-list{
    display: inline-block;
}

.card {
    margin: 10px;
    width: 200px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
.card-image {
    height: 200px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}
.card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card:hover .card-image {
    transform: scale(1.15);
}
.card-content {
    padding: 20px;
    background-color: #eac1ffc7;
}
.card-content h2 {
    margin-top: 0;
}
.card-content p {
    margin-bottom: 0;
}
