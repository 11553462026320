.buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.buttonContainer button {
    flex: 1;
    max-width: 45%;
}

@media (max-width: 900px) {
    .modalFinanceComponent {
        width: 70%;
        top: 35%;
        left: 41%;
        transform: translate(-37%, -45%);
    }
}
@media (min-width: 901px) {
    .modalFinanceComponent {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.modalFinanceComponent {
    font-size: 20px;
    position: fixed;
    background-color: #fff;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 8px;
    color: #121d4f;
}

.modalOverlayFinanceComponent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
}

.finance-table-th-title span{
    position: relative;
    top: -5px;
}
.finance-table-th-title svg{
    position: relative;
    top: 7px;
    left: -2px;
}
