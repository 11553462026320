.header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

.header-balance {
    position: relative;
    top: -4px;
    left: 37px;
}

.header-balance svg {
    position: relative;
    top: 3px;
    margin-left: 1px;
}

.header-balance a {
    color: #ffffff;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 8px;
}

