.localization-strings-modal-tooltip-target {
    position: relative;
    display: inline-block;
    margin: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    z-index: 2;

}

.localization-strings-modal-content {
    padding: 20px;
}


