.modalContent {
    padding: 20px;
    background-color: white; /* Белый фон */
    border: 1px solid #ccc; /* Серая граница */
    border-radius: 10px; /* Скругление углов */
    display: grid;
    /*grid-template-columns: repeat(5, 1fr);*/
    grid-gap: 20px;
    overflow: auto; /* Добавляем прокрутку, если содержимое превышает высоту */
    /* Дополнительные стили для позиционирования и размера модального окна */
    position: fixed; /* Или absolute, в зависимости от вашей реализации */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; /* Ширина модального окна */
    /*height: 70%; !* Высота модального окна *!*/
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень для лучшей видимости на разных фонах */
    padding: 20px; /* Достаточный padding, чтобы содержимое не касалось границ модального окна */
}

.modalOverlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backgroundColor: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
}
.imageContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* Пропорция для поддержания аспекта изображений, можно адаптировать под свои нужды */
}

.imagesGrid {
    display: grid;
    grid-gap: 10px;
}

@media (max-width: 900px) {
    .imagesGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 901px) {
    .imagesGrid {
        grid-template-columns: repeat(4, 1fr);
    }
}


.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Обрезка изображения для заполнения контейнера, сохраняя аспект */
}
.searchControls {
    font-size: 20px;
    max-height: 80px;
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; /* Или любой другой отступ, который вам нужен */
}

.buttonImageSearchComponent {
    width: 50%;
    font-size: 20px;
    background-color: #007bff; /* Синий цвет фона */
    color: white; /* Белый текст */
    padding: 10px 20px; /* Внутренние отступы */
    border: none; /* Убираем границу */
    border-radius: 5px; /* Скругляем углы */
    cursor: pointer; /* Курсор в виде руки при наведении */
    transition: background-color 0.3s ease; /* Плавное изменение фона при наведении */
}

.buttonImageSearchComponent:hover {
    background-color: #0056b3; /* Темно-синий цвет фона при наведении */
}

/*.photographer {*/
/*    text-align: center; !* Выравнивание текста по центру *!*/
/*}*/
