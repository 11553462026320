.menu-top-wrapper {
    background-color: #d7f9fa24;
    height: 50px;
    width: 45px;
}

.menu-top {
    height: 30px;
    position: relative;
    width: 100%;
    margin-left: 14px;
    padding-top: 18px;
    padding-bottom: 15px;
    padding-left: 5px;
    top: -13px;
    left: -13px;
}

.menu-top-container {
    background-color: #6163b9;
    box-shadow: inset 0 2px 9px rgb(255 255 255 / 20%), inset 0 -2px 6px rgb(0 0 0 / 20%);
    position: fixed; /* Или absolute */
    left: 0; /* Это выравнивает меню слева */
    top: 0; /* Или другие значения, если нужно */
    width: 300px; /* Ширина меню */
    height: 100%; /* Высота меню */
    z-index: 10000;
    border-radius: 10px;
    transition: transform 0.3s ease; /* Добавляем анимацию */
    transform: translateX(-300px); /* Сдвигаем меню за пределы экрана */
}

.menu-top-container.active {
    transform: translateX(0); /* Показываем меню */
}


.live-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: blur(2px);
}

.menu-items1 {
    /*position: absolute;*/
    margin-top: 38px;
    top: 100%;
    right: 0;
    list-style-type: none;
    /*background-color: #fff;*/
    /*border: 1px solid #ccc;*/
    padding: 10px;
    /*display: none; !* initially hide *!*/

    text-align: start !important;
    padding-left: 20px;
}

.menu-items1 li {
    margin-bottom: 10px;
}

.menu-icon {
    position: absolute;
    cursor: pointer;
    float: left;
    z-index: 10001;
}

.menu-top-link {
    color: #ffa9f0b3;
    text-decoration: none;
    padding-left: 20px;

    font-size: 21px;
    font-family: sans-serif;
}

.menu-top-icon {
    position: relative;
    top: 3px;
    color: #d77fee;
}
.active-menu {
    background-color: #9c27b0;
}
