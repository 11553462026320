@media (min-width: 900px) {
    .result-entry-answer-div {
        /*grid-template-columns: repeat(2, 1fr);*/
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: stretch;

    }
}

.result-entry-answer-div {
    display: grid;
}

.result-entry-written-answer-div {
    display: grid;
}

@media (min-width: 900px) {
    .result-entry-answer-text {
        /*width: 98%;*/
    }
}

.result-entry-answer-text {
    /*width: 95%;*/
    float: left;
    /*position: relative;*/
    top: 21px;
    min-height: 70px;
    padding: 5px 5px 5px 10px;
    border-radius: 7px;
    margin: 5px;
}


.result-entry-answer {
    /*margin-top: 10px;*/
}

.process-entry-result {
    border-radius: 5px !important;
    margin: 10px;
    background-color: #66666624;
    width: 47%;
    height: 74px;
    display: inline-block;
}

.incorrect-result {
    border: 3px /* width */ solid /* style */ magenta; /* color */
}


.correct-selected-result {
    border: 3px /* width */ solid /* style */ #137e00; /* color */
    background-color: #e4ffdf;
}

.incorrect-selected-result {
    border: 3px /* width */ solid /* style */ #7e0000; /* color */
    background-color: #8c0000;
}


.selected-result {
    background-color: #658bff;
}

.result-entry-svg {
    float: left;
    position: relative;
    top: 14px;
    padding-left: 10px;
}

.result-answer-border {
    box-sizing: border-box;
}

.result-answer-incorrect-selected {
    border: 3px /* width */ solid /* style */ #7e0000; /* color */
    background-color: #ffc6c6;
}

.result-answer-correct-selected {
    border: 3px /* width */ solid /* style */ #157300; /* color */
    background-color: #86ff7061;
}

.result-answer-incorrect-unselected {
    border: 3px /* width */ solid /* style */ #7e0000; /* color */
    background-color: #ffffff;
}

.result-answer-correct-unselected {
    border: 3px /* width */ solid /* style */ #157300; /* color */
    background-color: #ffffff;
}

.result-answer-sound {
    width: 30px;
    float: left;
    margin-left: -10px;
    margin-top: -7px;
    /*position: relative;*/
    /*top: 70px;*/
    /*z-index: 1000;*/
}

.colorbox {
    position: relative; /* Создаем контекст позиционирования для дочерних элементов */
}

.result-answer-image {
    top: 0px !important;
    float: right;
    border-radius: 7px !important;
    top: 10px;
    right: 10px;
    margin: 5px;
    width: 110px;
    height: 110px;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 900px) {
    .result-answer-image{
        width: 100px !important;
        height: 100px !important;
    }
}


.result-container-entry {
    /*margin: 0px 20px 20px 20px;*/
    /*padding: 10px 10px 10px 10px;*/
    color: #121D4F;
    font-size: 15px;
    background-color: #ffffff;
    display: grid;
    gap: 1.5rem;
    /*grid-template-columns: 1fr 1fr;*/
    border-radius: 7px;
    margin-bottom: 20px;
}

.result-container-entry-inner {
    float: left;
}

.result-container-entry-inner-header {
    /*padding-left: 40px;*/
    font-size: 30px;
}
.result-entry-question-icon-dynamic{
    margin-top: -7px;
    float: left;
}


@media (max-width: 900px) {
    .container-entry-question {
        width: 98% !important;
    }
}

.container-entry-question {
    width: 99%;
    display: grid;
    padding: 5px 5px 5px 5px;
}

/* Пример медиа-запроса для мобильных устройств */
@media only screen and (max-width: 900px) {
    .recharts-layer {
        width: 150px; /* Изменяем ширину на мобильных устройствах */
        height: 150px; /* Изменяем высоту на мобильных устройствах */
    }
}

/* Пример медиа-запроса для десктопов */
@media only screen and (min-width: 901px) {
    .recharts-layer {
        width: 200px; /* Изменяем ширину на десктопах */
        height: 200px; /* Изменяем высоту на десктопах */
    }
}


@media (min-width: 900px) {
    .result-container-summary-with-chart {
        display: flex !important;
    }
}

.result-container-summary-with-chart {
    display: block;
}


.result-container-summary-details {
    padding: 20px;
    color: rgb(136, 152, 169);
}

.pie-chart{
    display: ruby-text;
}
.result-answer-comment{
    color: #275749;
    font-style: italic;
}

.container-entry-status {
    padding: 10px;
}
