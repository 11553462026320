.lets-start-header {
    position: relative;
    overflow: hidden;
    z-index: 1;
    height: 367px;
}

.lets-start-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
    animation: lets-start-parallaxMove 5s linear forwards;
    z-index: 0;
}

.lets-start-header-content {
    position: relative;
    max-width: 900px;
    margin: 0 auto;
    padding-top: 61px;
    text-align: center;
    z-index: 1;
}

.lets-start-header-title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 36px !important;
    color: #715ab7;
}

.lets-start-header-description {
    margin-top: 0;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #97488c;
}
@media (max-width: 900px) {
    .lets-start-header-description {
        font-size: 18px;
    }
}
@media (min-width: 901px) {
    .lets-start-header-description {
        font-size: 25px;
    }
}



.lets-start-btn-start {
    display: inline-block;
    padding: 12px 24px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
}

.lets-start-btn-start:hover {
    background-color: #45a049;
}

@keyframes lets-start-parallaxMove {
    0% { transform: translateY(0); }
    100% { transform: translateY(-6%); }
}

@keyframes lets-start-waveEffect {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
}

.lets-start-glow-on-hover {
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5); /* Добавляем тень к тексту для улучшения читаемости на ярком фоне */
}

.lets-start-text {
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    -webkit-background-clip: text; /* Использовать текст как маску для градиента */
    background-clip: text;
    color: transparent; /* Скрыть оригинальный цвет текста */
}

.lets-start-text:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: 1000px;
    height: 100px;
    animation: lets-start-glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.99); /* Добавляем тень к тексту для улучшения читаемости на ярком фоне */
}

.lets-start-text:hover:before {
    opacity: 1;
}

@keyframes lets-start-glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.lets-start-glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: lets-start-glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.99); /* Добавляем тень к тексту для улучшения читаемости на ярком фоне */
}

.lets-start-glow-on-hover:hover:before {
    opacity: 1;
}

@keyframes lets-start-glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

@keyframes lets-start-bgChange {
    0% {
        background-position: 0% 0%, 100% 0%, 200% 0%, 300% 0%, 400% 0%;
    }
    20% {
        background-position: -100% 0%, 0% 0%, 100% 0%, 200% 0%, 300% 0%;
    }
    40% {
        background-position: -200% 0%, -100% 0%, 0% 0%, 100% 0%, 200% 0%;
    }
    60% {
        background-position: -300% 0%, -200% 0%, -100% 0%, 0% 0%, 100% 0%;
    }
    80% {
        background-position: -400% 0%, -300% 0%, -200% 0%, -100% 0%, 0% 0%;
    }
    100% {
        background-position: -500% 0%, -400% 0%, -300% 0%, -200% 0%, -100% 0%;
    }
}

.lets-start-animated-text {
    color: transparent;
    background-clip: text;
    background-image: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
}

.lets-start-animated-text {
    color: transparent;
    background-clip: text;
    background-image: linear-gradient(245deg, #e024f5, #26d9d3, #071bb5, #ecadff, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    animation: lets-start-bgChange 20s ease infinite;
    background-size: 500% 100%;
}

@keyframes lets-start-animatedGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.lets-start-animated-text {
    background: linear-gradient(90deg, #ff00cc, #3333ff, #00ccff);
    background-size: 200% 200%;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: lets-start-animatedGradient 3s ease infinite;
}
