.login-container {
    background-color: rgba(255, 255, 255, 0.8); /* Чтобы контейнер выделялся на фоне */
    border-radius: 10px; /* Добавляем скругление углов */
    padding: 20px; /* Добавляем отступы внутри контейнера */
    box-shadow: 0 0 10px rgb(82, 82, 82); /* Добавляем тень для эффекта поднятия */
    max-width: 400px; /* Ограничиваем максимальную ширину контейнера */
}

.form-group {
    margin-bottom: 20px; /* Добавляем отступ между элементами формы */
}

input[type="text"],
input[type="email"],
input[type="password"] {
    width: calc(100% - 20px); /* Занимаем всю доступную ширину, учитывая отступы */
    padding: 10px; /* Добавляем отступы внутри полей ввода */
    /*border: 1px solid #a4a4a4; !* Добавляем границу полям ввода *!*/
    border-radius: 5px; /* Добавляем скругление углов */
    background-color: rgba(255, 255, 255, 0.9); /* Добавляем фоновый цвет полям ввода */
}

button[type="submit"] {
    width: 100%; /* Занимаем всю доступную ширину */
    padding: 10px; /* Добавляем отступы внутри кнопки */
    border: none; /* Убираем границу кнопки */
    border-radius: 5px; /* Добавляем скругление углов */
    background-color: #007bff; /* Цвет фона кнопки */
    color: #fff; /* Цвет текста кнопки */
    cursor: pointer; /* Устанавливаем курсор в виде указателя */
}

button[type="submit"]:hover {
    background-color: #0056b3; /* Цвет фона кнопки при наведении */
}
