

.home-container {
    border: 1px solid #ccc;
    border-radius: 17px;
    background-color: #f9f9f9;
    color: #282c34;
    width: 42%;

    position: relative;
    top: -54px;
    /* margin: 20px; */
    padding: 20px;
    left: 27%;
    z-index: 1!important;
}

@media (max-width: 900px) {
    .home-container {
        border: 1px solid #ccc;
        border-radius: 17px;
        background-color: #f9f9f9;
        color: #282c34;
        width: 84%;
        position: relative;
        top: -6px;
        /* margin: 20px; */
        padding: 20px;
        left: 8%;
        z-index: 1 !important;
    }
    .quiz-banner {
        width: 100% !important;
    }
}

/*body, html {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;*/
/*    background-color: #f5f5f5;*/
/*    color: #333;*/
/*}*/

header {
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    color: white;
    text-align: center;
    /*padding: 40px 20px;*/
}

header h1 {
    margin: 0;
    font-size: 2.5rem;
}

header p {
    margin-top: 10px;
    font-size: 1.2rem;
}

.main {
    /*padding: 20px;*/
}

.container-home {
    /*max-width: 1200px;*/
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.quiz-card {
    margin: 10px;
    padding: 20px;
    width: calc(33% - 20px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.quiz-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

h2 {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}




.quiz-card img {
    width: 100px; /* Устанавливаем желаемый размер изображения */
    height: 100px; /* Устанавливаем желаемый размер изображения */
    object-fit: cover; /* Обеспечиваем, чтобы изображения надлежащим образом заполняли свое пространство */
    border-radius: 50%; /* Делаем изображения круглыми */
    margin-bottom: 10px; /* Добавляем немного пространства между изображением и текстом */
}



.quiz-card {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: center;
    margin: 10px;
    height: 200px; /* Устанавливаем высоту баннера */
    width: calc(33% - 20px); /* Управляем шириной баннера */
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    border-radius: 10px;
    background-color: white;
    background-size: cover; /* Обеспечиваем покрытие всего блока изображением */
    background-position: center; /* Центрируем изображение */
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    padding: 20px;
    color: white; /* Меняем цвет текста на белый для лучшей видимости */
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5); /* Добавляем тень к тексту для улучшения читаемости на ярком фоне */
}

.quiz-card span {
    font-size: 1.5em;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.5); /* Добавляем полупрозрачный фон для текста */
    border-radius: 5px;
    padding: 5px 10px;
}

.quiz-banner {
    position: relative;
    height: 300px; /* Задаем высоту баннера */
    margin: 20px 0;
    border-radius: 10px;
    background-size: cover; /* Гарантируем, что фоновое изображение покроет весь баннер */
    background-position: center; /* Центрируем фоновое изображение */
    display: flex;
    align-items: flex-end; /* Выравниваем текст в нижней части баннера */
    justify-content: center;
    cursor: pointer;
    overflow: hidden; /* Убираем выходящие за пределы баннера элементы */
}

.quiz-banner span {
    width: 100%; /* Заставляем текст занимать всю ширину баннера */
    background-color: rgba(0, 0, 0, 0.5); /* Делаем фон для текста полупрозрачным, чтобы он выделялся на фоне изображения */
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 10px 0; /* Добавляем отступы для текста */
}
.quiz-banner {
    margin: 10px 0;
    width: 30%;
    position: relative;
    height: 300px; /* Задаем высоту баннера */
    margin: 20px 0;
    border-radius: 10px;
    background-size: cover; /* Гарантируем, что фоновое изображение покроет весь баннер */
    background-position: center; /* Центрируем фоновое изображение */
    display: flex;
    align-items: flex-end; /* Выравниваем текст в нижней части баннера */
    justify-content: center;
    cursor: pointer;
    overflow: hidden; /* Убираем выходящие за пределы баннера элементы */
}

.quiz-banner span {
    width: 100%; /* Заставляем текст занимать всю ширину баннера */
    background-color: rgba(0, 0, 0, 0.5); /* Делаем фон для текста полупрозрачным, чтобы он выделялся на фоне изображения */
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 10px 0; /* Добавляем отступы для текста */
}

.banners-container {
    justify-content: center; /* Выравнивание по горизонтали */
    align-items: center;
    /*min-height: 900px;*/

    padding-bottom: 30px;
    width: 100%;
    background-image: url('https://localhost:8894/images/home/quizland_background.jpg'); /* Путь к фоновому изображению */
    background-size: cover; /* Или используйте 100% 100% для точного растягивания */
    background-position: center;
    background-repeat: repeat; /* Это предотвратит повторение изображения */

    /*padding: 40px 20px; !* Добавляем некоторый внутренний отступ *!*/
    border-radius: 15px; /* Слегка скругляем углы контейнера */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Добавляем тень для дополнительной глубины */
    /*margin: 20px auto; !* Центрируем контейнер на странице *!*/
    width: 100%; /* Ограничиваем максимальную ширину контейнера */
}

.banners-container-2{
    top: 100px;
    height: 700px;
    width: 100%;
    /*background-image: url('https://localhost:8894/images/home/quizland_background2.jpg'); !* Путь к фоновому изображению *!*/
    background-size: cover; /* Или используйте 100% 100% для точного растягивания */
    background-position: center;
    background-repeat: repeat; /* Это предотвратит повторение изображения */

    /*padding: 40px 20px; !* Добавляем некоторый внутренний отступ *!*/
    border-radius: 15px; /* Слегка скругляем углы контейнера */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Добавляем тень для дополнительной глубины */
    /*margin: 20px auto; !* Центрируем контейнер на странице *!*/
    width: 100%; /* Ограничиваем максимальную ширину контейнера */
}
