.answer-input-container {
    /*width: 70%;*/
    display: block; /* Используем flexbox для выравнивания элементов в ряд */
    align-items: center; /* Центрируем элементы по вертикали */
    justify-content: space-between; /* Распределяем пространство между элементами */
    margin-bottom: 10px; /* Добавляем немного отступа между строками для читаемости */
}

.answer-input-container input {
    flex-grow: 1;
    margin-right: 10px;
    height: 40px;
    font-size: 25px;
}



.answer-input-container button {
    flex-shrink: 0;
    font-size: 25px;
    min-width: 50px;
    cursor: pointer;
}
.written-answer-input {
    width: 100%; /* Задаёт ширину на 100% контейнера */
    border: 1px solid #ccc; /* Лёгкая граница для визуального выделения */
    font-family: inherit; /* Использует шрифт, заданный для всего приложения */
    padding: 8px; /* Небольшой внутренний отступ */
    box-sizing: border-box; /* Гарантирует, что padding не добавляет дополнительную ширину */
    resize: vertical; /* Позволяет пользователю изменять размер только вертикально */
    font-size: 20px;
    border-radius: 5px; /* Скругляем углы */

}
