/*.contents-list {*/
/*    grid-template-columns: repeat(2, 1fr);*/
/*    margin: 20px;*/
/*}*/


.content-component__field {
    color: #26283c;
    font-size: 25px !important;
    background: linear-gradient(135deg, rgba(234, 224, 245, 0.99), rgb(248, 226, 255), rgba(226, 234, 255, 0.95), rgba(227, 255, 232, 0.99));
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    /*padding: 30px;*/
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, border-radius 0.5s ease-in-out, background-color 1s ease-in-out !important;
    transform: scale(1);
}

.content-component__form-container {
    background: linear-gradient(135deg, rgba(229, 216, 238, 0.99), rgb(232, 202, 241), rgba(199, 215, 255, 0.95), rgba(209, 243, 215, 0.99));
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    padding: 30px;
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, border-radius 0.5s ease-in-out, background-color 1s ease-in-out !important;
    transform: scale(1);
}

.content-component__form-container:hover {
    transform: scale(1.01);
    background: linear-gradient(235deg, rgba(224, 215, 233, 0.97), rgba(217, 165, 226, 0.97), rgba(180, 197, 236, 0.90), rgba(175, 208, 179, 0.90));
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
}

@media (min-width: 901px) {
    .content-component {
        width: 80%;
    }
}
@media (max-width: 900px) {
    .content-component {
        width: 100%;
    }
}

.content-component {
    margin: 0 auto;
}

.react-quill-editor {
    height: 500px;
}

.ql-container {
    min-height: 100%;
}


.contents-wrapper {
    overflow-x: auto;
    max-width: 100%;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.contents-wrapper::-webkit-scrollbar { /* Стилизация скроллбара для браузеров, использующих WebKit (Chrome, Safari) */
    width: 8px; /* Ширина скроллбара */
}

.contents-wrapper::-webkit-scrollbar-track {
    background-color: transparent; /* Фон трека скроллбара */
}

.contents-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Цвет ползунка скроллбара */
    border-radius: 4px; /* Скругление углов ползунка */
}



.contents-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin: 20px;
}

.content-container{
    width: 300px;
    max-width:300px !important;
}
