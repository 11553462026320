.login-top{
    margin-right: 10px;
    position: relative;
    top: -7px;
}

.login-top svg{
    position: relative;
    margin-right: 0px;
    top: 6px;
}
.login-top-text {
    color: #ffa9f0b3;
    text-decoration: none;
    font-size: 18px;
    font-family: sans-serif;
}

