
.animatedTextWaveGradient:hover {
    color: transparent;
    background-clip: text;
    background-image: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
}

.animatedTextWaveGradient:hover {
    color: transparent;
    background-clip: text;
    background-image: linear-gradient(245deg, #e024f5, #26d9d3, #071bb5, #ecadff, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    animation: bgChange 20s ease infinite;
    background-size: 500% 100%;
}

@keyframes bgChange {
    0% {
        background-position: 0% 0%;
    }
    20% {
        background-position: -100% 0%;
    }
    40% {
        background-position: -200% 0%;
    }
    60% {
        background-position: -300% 0%;
    }
    80% {
        background-position: -400% 0%;
    }
    100% {
        background-position: -500% 0%;
    }
}
