/*.parallax {*/
/*    background-image: url('https://source.unsplash.com/random?wallpapers');*/
/*    background-attachment: fixed;*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*}*/

.form-container {
    background: linear-gradient(135deg, rgba(197, 184, 208, 0.95), rgba(198, 187, 214, 0.95), rgba(180, 197, 236, 0.95), rgba(175, 208, 179, 0.95));
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    padding: 30px;
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, border-radius 0.5s ease-in-out, background-color 1s ease-in-out !important;
    transform: scale(1);
}

.form-container:hover {
    transform: scale(1.01);
    background: linear-gradient(235deg, rgba(224, 215, 233, 0.90), rgba(217, 165, 226, 0.90), rgba(180, 197, 236, 0.85), rgba(175, 208, 179, 0.85));
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
}

#uid, #password {
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

button.form-group {
    background-color: #f50057;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.5s ease, transform 0.5s ease-in-out !important;
    transform: scale(1);
}

button.form-group:hover {
    background-color: #c51162;
    transform: scale(1.01);
}

/*.text-field {*/
/*    transform: scale(1);*/
/*    transition: transform 0.5s ease-in-out !important;*/
/*}*/

/*.text-field:hover {*/
/*    transform: scale(1.005);*/
/*}*/

.avatar {
    background-color: #f50057;
}

.typography {
    font-family: 'Roboto', sans-serif;
}


