.welcome-screen {
    position: relative;
    animation: fadeIn 1s, fadeOut 2s 4s; /* Изменить на 4 секунды для fadeOut */
    animation-fill-mode: forwards;
    height: 100vh;
    width: 100vw;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

.welcome-text {
    position: relative;
    animation: moveUp 4s ease-in-out;
    animation-fill-mode: forwards;
    text-align: center;
    max-width: 90%;
    word-wrap: break-word;
}

@keyframes moveUp {
    from { top: 0px; opacity: 0; }
    to { top: -150px; opacity: 1; }
}

.parallax-bg {
    animation: parallaxMove 28s linear forwards;
    position: absolute;
    background-color: #9c27b0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

@keyframes parallaxMove {
    0% { transform: scale(1) translateY(0); }
    100% { transform: scale(1.06) translateY(-6%); }
}

.welcome-screen.show {
    opacity: 1;
    pointer-events: auto;
}

.welcome-screen.hide {
    opacity: 0;
    pointer-events: none;
}

.parallax-bg {
    transition: background-position 0.1s ease-in-out;
}
