
@import url('Animated-effects.css');
@import url('LocalizedStringsModal.css');


.navigation-list-icon {
    /*height: 1.95rem;*/
}
.navigation-list-icon svg {
    margin-right: 10px;
}

.navigation-list-data-icon {
    color: #1976d2;
}
.navigation-list-data-icon:hover {
    color: #f557de !important;
}

.color-grey {
    color: #8a8a8a;
}

.cursor-pointer {
    cursor: pointer;
}


.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #1976d2;

    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #1976d2
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #1976d2b3;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}


.scale-effect-1 {
    transition:transform 0.3s ease-in-out !important;
    transform: scale(1);
}

.scale-effect-1:hover {
    transform: scale(1.01);
}


.scale-effect-2 {
    transition:transform 0.3s ease-in-out !important;
    transform: scale(1);
}

.scale-effect-2:hover {
    transform: scale(1.02);
}

.scale-effect-5 {
    transition:transform 0.3s ease-in-out !important;
    transform: scale(1);
}

.scale-effect-5:hover {
    transform: scale(1.05);
}

.scale-effect-10 {
    transition:transform 0.3s ease-in-out !important;
    transform: scale(1);
}

.scale-effect-10:hover {
    transform: scale(1.1);
}

.scale-effect-20 {
    transition: transform 0.3s ease-in-out !important;
    transform: scale(1);
}

.scale-effect-20:hover {
    transform: scale(1.2);
}

.scale-effect-30 {
    transition: transform 0.3s ease-in-out !important;
    transform: scale(1);
}

.scale-effect-30:hover {
    transform: scale(1.3);
}


.scale-effect-50 {
    transition: transform 0.3s ease-in-out !important;
    transform: scale(1);
}

.scale-effect-50:hover {
    transform: scale(1.5);
}

.scale-effect-70 {
    transition: transform 0.3s ease-in-out !important;
    transform: scale(1);
}

.scale-effect-70:hover {
    transform: scale(1.7);
}

.scale-effect-100 {
    transition: transform 0.3s ease-in-out !important;
    transform: scale(1);
}

.scale-effect-100:hover {
    transform: scale(2);
}


.body {
    position: relative;
    overflow: hidden; /* Для предотвращения прокрутки, если размытие выходит за пределы контейнера */
    margin: 0 !important;
}

/*.body::before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    background-image: url('https://get.pxhere.com/photo/leather-texture-photo-dark-pattern-line-blue-circle-background-design-bright-beautiful-computer-wallpaper-658860.jpg');*/
/*    background-attachment: fixed;*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    filter: blur(1px); !* Эффект размытия *!*/
/*    z-index: -1; !* Размещение фона позади контента *!*/
/*}*/

/*.content {*/
/*    position: relative;*/
/*    z-index: 1; !* Обеспечивает, что контент будет поверх размытого фона *!*/
/*}*/


/*.body:hover {*/
/*    !*background: linear-gradient(235deg, rgba(224, 215, 233, 0.90), rgba(217, 165, 226, 0.90), rgba(180, 197, 236, 0.85), rgba(175, 208, 179, 0.85));*!*/
/*}*/


.container-background-gradient {
    background: linear-gradient(135deg, rgba(119, 72, 160, 0.95), rgba(198, 187, 214, 0.95), rgba(180, 197, 236, 0.95), rgb(63, 141, 132));
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, border-radius 0.5s ease-in-out, background-color 1s ease-in-out !important;
    transform: scale(1);
}

.container-background-gradient-light {
    background: linear-gradient(135deg, rgba(186, 143, 222, 0.95), rgba(192, 170, 226, 0.95), rgba(150, 177, 239, 0.95), rgb(114, 202, 214));
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, border-radius 0.5s ease-in-out, background-color 1s ease-in-out !important;
    transform: scale(1);
    color: #fae3ff;
    font-size: larger;
}

.container-background-gradient-dark {
    background: linear-gradient(135deg, rgb(107, 13, 97), rgba(129, 104, 167, 0.95), rgba(68, 93, 153, 0.95), rgb(18, 91, 102));
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, border-radius 0.5s ease-in-out, background-color 1s ease-in-out !important;
    transform: scale(1);
}

.gradient-blue-purple {
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%) !important;
    color: white;
    text-align: center;
}

