.quiz-processes-stat-image{
    float: right;
    border-radius: 5px !important;
    width: 25px;
    height: 25px;
}

.quiz-processes-table td:nth-child(1){
    width: 10px;
    text-align: center;
    font-size: 10px;
}
.quiz-processes-table td:nth-child(2){
    width: 5px;
    text-align: center;
    font-size: 8px;
}
.quiz-processes-table td:nth-child(3) {
    text-align: left;
    font-size: 10px;
}
.quiz-processes-table td:nth-child(4),
.quiz-processes-table td:nth-child(5),
.quiz-processes-table td:nth-child(6),
.quiz-processes-table td:nth-child(7),
.quiz-processes-table td:nth-child(8) {
    width: 10px;
    text-align: center;
    font-size: 10px;
}

.quiz-processes-change-interval input{
    height: 30px;
    width: 40px;
    font-size: 18px;
}
