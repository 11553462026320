.tariff-wrapper {
   overflow-x: auto;
    overflow-y: auto;

    max-width: 100%;
    position: relative;
    background-color: #000; /* Цвет фона для изображения */
}

.tariff-wrapper::before {
    /*content: '';*/
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0.3; /* Прозрачность изображения */
    z-index: -1;
    transition: transform 0.3s ease; /* Анимация эффекта параллакса */
}

.tariff-wrapper:hover::before {
    transform: scale(1.1); /* Увеличение масштаба при наведении */
}

.tariff-wrapper::-webkit-scrollbar { /* Стилизация скроллбара для браузеров, использующих WebKit (Chrome, Safari) */
    width: 2px;
    height: 2px;
}

.tariff-wrapper::-webkit-scrollbar { /* Скрытие скроллбара для браузеров, использующих WebKit (Chrome, Safari) */
    display: none;
}

.tariff-list {
    color: #967297;
    display: -webkit-inline-box;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 50px;
    padding: 30px;
}

.tariff-container {
    position: relative;
    cursor: grab;
}

.tariff-container.dragging {
    cursor: grabbing;
}


@media (min-width: 901px) {
    .tariff-container {
        width: 440px;
    }
}
@media (max-width: 900px) {
    .tariff-container {
        width: 50%;
    }
}

.tariff-banner {
    width: 100%;
    position: relative;
    height: 300px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
}

.tariff-banner span {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
    height: 64px;
}


.tariff {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.tariff-image {
    height: 285px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}

.tariff-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tariff:hover .tariff-image {
    transform: scale(1.05);
}

.tariff-content {
    padding: 20px;
    background: linear-gradient(180deg, rgba(224, 215, 233, 0.50), rgba(217, 165, 226, 0.50), rgba(180, 197, 236, 0.6), rgba(175, 208, 179, 0.6));
    /*background-color: #eac1ffc7;*/
    flex: 1;
}

.tariff-content h2 {
    margin-top: 0;
}

.tariff-content p {
    margin-bottom: 0;
}

.control-fixed-bottom {
    position: sticky;
    bottom: 10px;
    right: 10px;
    z-index: 1000;
    left: 25%;
    width: 50%;
}

.save-button {
    background-color: #6200ea;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.save-button:hover {
    background-color: #3700b3;
}

.button-container {
    text-align: center; /* Горизонтальное выравнивание по центру */
}
