.user-table-wrapper {
    overflow-x: auto;
    max-width: 100%;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.user-table-wrapper::-webkit-scrollbar { /* Стилизация скроллбара для браузеров, использующих WebKit (Chrome, Safari) */
    width: 8px; /* Ширина скроллбара */
}

.user-table-wrapper::-webkit-scrollbar-track {
    background-color: transparent; /* Фон трека скроллбара */
}

.user-table-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Цвет ползунка скроллбара */
    border-radius: 4px; /* Скругление углов ползунка */
}

.users-table {
    width: 1300px; /* Фиксированная ширина таблицы */
}

.users-table td {
    padding-left: 3px;
    padding-right: 3px;
}

.users-table td:nth-child(1),
.users-table td:nth-child(2),
.users-table td:nth-child(3) {
    padding-left: 10px;
    max-width: 120px;
    overflow-x: auto;
    white-space: nowrap;
    width: 120px;
    font-size: 14px;
    text-align: left;
}
.users-table td::-webkit-scrollbar {
    width: 3px; /* Толщина вертикального скролла */
    height: 3px; /* Толщина горизонтального скролла */
}

.users-table td:nth-child(4) {
    text-align: left;
    font-size: 14px;
    color: #ffffff;
    width: 120px;
}

.users-table td:nth-child(4) div {
    text-align: left;
    font-size: 12px;
    color: #ffffff;
    width: 100%;

}

.users-table td:nth-child(4) label {
    font-size: 14px !important;
    color: #1976d2;
    margin-top: 8px;
}


.users-table td:nth-child(5),
.users-table td:nth-child(6) {
    max-width: 300px;
    text-align: left;
    font-size: 12px;
    color: #ffffff;
    width: 300px;
}

.users-table td:nth-child(7),
.users-table td:nth-child(8),
.users-table td:nth-child(9) {
    width: 10px;
    text-align: center;
    font-size: 10px;
}

.users-table td:nth-child(10) {
    text-align: left;
    font-size: 12px;
    color: #ffffff;
    width: 150px;
}


.users-table tr:nth-child(even) {
    background-color: #4b5791;
}


.search-form {
    margin: 10px;
    display: flex;
    align-items: center;
}

.search-form form {
    display: flex;
    flex-grow: 1;
}

.search-form form .MuiTextField-root {
    flex-grow: 1;
    margin-right: 8px;
}

.search-form form .MuiButton-root {
    min-width: 80px;
}

button[type="submit"] {
    /*width: 20%;*/
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.small-button {
    padding: 4px 8px !important;
    font-size: 12px !important;;
    width: 134px !important;;
    min-height: 32px !important;;
    height: 22px !important;
    margin: 3px !important;
}
