.loading {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #000;
}


@media (max-width: 900px) {
    .container {
        margin: 2px;
        padding: 2px;
    }
}
@media (min-width: 901px) {
    .container {
        margin: 20px;
        padding: 20px;
    }
}
.container {
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #282c34;
    min-height: 200px;
}

.title {
    font-size: 22px;
    color: #17124f;
    margin-top: 4px;
    position: relative;
    left: 44px;
}

.description {
    font-size: 16px;
    margin-bottom: 20px;
    color: #000;
}

@media (max-width: 900px) {
    .questionContainer{
        padding: 1px;
    }
}
@media (min-width: 901px) {
    .questionContainer{
        padding: 10px;
    }
}

.questionContainer {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    margin-bottom: 20px;
    /*display: flex;*/
    align-items: flex-start;
    display: block;
}

.questionColumn {
    display: block;
    /*margin-right: 20px;*/
    flex: 1;
}

.answerColumn {
    flex: 2;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;
}

.questionNumber {
    font-size: 20px;
    margin-right: 10px;
    color: #000;
}
.quiz-component-entry-star{
    position: relative;
    top: 9px;
    left: 2px;
}

.questionText {
    border-radius: 5px;
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    font-size: 14px;
    line-height: 1.5;
    transition: height 0.2s;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 4em;
    min-height: 15px;
    overflow-x: hidden;
    resize: none;
}

.answerItem {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.answerItem__correct {
    border: 2px solid #c6d7c0;
}

.answerItem__incorrect {
    border: 2px solid #dba6a6;
}

.answerText {
    font-size: 14px;
    margin-bottom: 5px;
    color: #000;
}

.answerImage {
    width: 100%;
    max-width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
}
.fixed-top {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    /*width: 54%;*/
    height: 50px;
    margin: auto;
    padding: 10px;
    box-sizing: border-box;
    z-index: 1000;
}

.save-button {
    /* Add your button styles here */
    padding: 10px;
    /* Add more styles as needed */
}

.textarea-description {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: vertical; /* Позволяет пользователю изменять размер только вертикально */
    min-height: 100px; /* Минимальная высота */
    font-size: 16px; /* Размер шрифта */
    color: #333; /* Цвет текста */
}


.user-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 15px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.user-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.users-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-detail {
    font-size: 14px;
    color: #666;
    margin: 5px 0;
}

.user-role {
    display: inline-block;
    background-color: #0056b3;
    color: white;
    padding: 3px 7px;
    border-radius: 5px;
    font-size: 12px;
    margin-right: 5px;
}

/* Добавьте эти стили в файл QuizComponent.css */

.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1050; /* Убедитесь, что модальное окно находится над другими элементами */
    outline: none; /* Убирает контур, если модальное окно в фокусе */
    color: #121d4f;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
    z-index: 1040; /* Убедитесь, что оверлей находится под модальным окном и над другими элементами */
}

.add-user-form {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.add-user-form input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.add-user-form button {
    padding: 8px 12px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.add-user-form p {
    margin-top: 10px;
    color: green; /* Или red, если сообщение об ошибке */
}

.number100 {
    font-size: large;
    min-height:30px;
    max-width: 50px;
    border: 1px solid #e0e0e0; /* Синяя граница */
    border-radius: 4px; /* Скругление углов */
    padding: 5px 10px; /* Внутренние отступы */
    outline: none; /* Убрать стандартный фокус браузера */
    transition: border-color 0.3s, box-shadow 0.3s; /* Плавный переход */
}

.number100:focus {
    border-color: #0056b3; /* Темно-синяя граница при фокусе */
    box-shadow: 0 0 0 3px rgba(0,123,255,0.25); /* Светлая тень вокруг поля */
}

.number100:hover {
    border-color: #0056b3; /* Темно-синяя граница при наведении */
}

.toggle-switch {
    margin-left: 10px;
    position: relative;
    display: inline-block;
    width: 40px; /* Уменьшено с 60px до 40px */
    height: 24px; /* Уменьшено с 34px до 24px */
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 24px; /* Уменьшено для сохранения пропорциональности */
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px; /* Уменьшено с 26px до 18px */
    width: 18px; /* Уменьшено с 26px до 18px */
    left: 3px; /* Оптимизировано для меньшего размера */
    bottom: 3px; /* Оптимизировано для меньшего размера */
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    transform: translateX(16px);
}

.quiz-entry-answer-comment{
    margin-top: 8px;
    font-style: italic;
    color: #666;
}

.questionNumberContainer{
    display: flex;
    align-items: center;
}
.questionNumberContainer button {
   /*margin-right: 10px;*/
}

.questionNumberContainer input{
    height: 25px;
    max-width: 34px;
    font-size: 18px;
}



@media (max-width: 900px) {
    .modalComponent {
        width: 70%;
        top: 35%;
        left: 41%;
        transform: translate(-37%, -45%);
    }
}
@media (min-width: 901px) {
    .modalComponent {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.modalComponent {
    font-size: 20px;
    position: fixed; /* Фиксированное позиционирование */
    background-color: #fff; /* Белый фон */
    padding: 20px;
    z-index: 1000; /* Убедитесь, что модальное окно находится над другими элементами */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Тень для выделения */
    border-radius: 8px; /* Скругление углов */
    color: #121d4f;
}

.modalOverlayComponent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Затемнение фона */
    z-index: 999; /* Немного ниже, чем само модальное окно */
}
.modalComponent button {
    background-color: #007bff; /* Синий фон */
    color: white; /* Белый текст */
    border: none; /* Убрать стандартную рамку */
    padding: 10px 20px; /* Паддинги для увеличения размера кнопки */
    margin: 0 10px; /* Отступы между кнопками */
    border-radius: 5px; /* Скругление углов */
    cursor: pointer; /* Курсор в виде руки при наведении */
    transition: background-color 0.3s ease; /* Плавное изменение фона при наведении */

    /*position: fixed; !* Фиксированное позиционирование *!*/
    bottom: 20px; /* Расположить кнопку в 20px от нижнего края окна */
    left: 50%; /* Расположить кнопку по центру окна */
    /*transform: translateX(-50%); !* Центрирование кнопки *!*/
    z-index: 1000; /* Установить высокий z-index, чтобы кнопка была поверх других элементов */
}

.modalChangeOrder button:hover {
    background-color: #0056b3; /* Темно-синий фон при наведении */
}

.button-gray {
    background-color: gray !important;
    color: white!important;
    /* Add other styling as needed */
}
.remove-answer-btn{
    margin-left: auto;
}


@media (max-width: 900px) {
    .menu-quiz-edit {
        width: 93%;
    }
}
@media (min-width: 901px) {
    .menu-quiz-edit {
        width: 300px;
    }
}

.menu-quiz-edit{
    position: absolute;
    display: grid;
    float: left;
    background-color: #f7fcff;
    z-index: 99999;
}

.menu-quiz-edit-button-control{
    position: absolute;
    float: left;
    z-index: 1001;
    color: #d77fee;
}

.quiz-entry-answer-image-button-control{
    display: inline-flex;
    width: 100%;
}
