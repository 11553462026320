@media (max-width: 900px) {

    .quiz-entry-result-success {
        margin-left: 10% !important;
        width: 80% !important;
    }

    /*@media (min-width: 300px) and (max-width: 600px) {*/
    .report-pie{
        width: 100% !important;
    }

    .body {
        width: 100%;
        margin: 0px !important;
    }

    .result-body {
        width: 100%;
    }

    #quizform {
        width: 100%;
        margin-top: 0px !important;
    }

    .mobile-hidden {
        display: none;
    }

    .quiz-entry-answer-text {
        width: 70%;
        font-size: 25px;
        left: 3px !important;
        line-height: 1.1;
    }

    .radiocontainer {
        /*padding: 20px 52px 79px 50px !important;*/
        margin: 10px;
        min-height: 70px !important;
        display: flex !important;
        align-items: center;
    }

    .checkmark {
        /*top: 93px !important;*/
        height: 33px !important;
        width: 33px !important;
    }

    .top-menu-link{
        font-size: 18px !important
    }


    .quiz-result-container-entry{
        margin: 0px 5px 5px 5px !important;
        padding: 0px 5px 5px 5px !important;
    }

    .answerbutton {
        font-size: 27px !important;
        padding: 10px 56px !important;
        border-radius: 10px !important;
        width: 100%;
    }

    .quiz-entry-top-info {
        font-size: 12px !important;
    }

    .quiz-entry-result-answer-text {
        font-size: 30px;
    }

    .quiz-entry-question-image {
        height: 200px !important;
    }

    .quiz-entry-answer-image-link {
        max-width: 27% !important;
    }
}

@media (min-width: 901px) {
    .result-body {
        width: 70%;
        margin: 0 auto;
    }
}

#id-result-body {
    min-height: 300px;
}

.result-body-edit {
    width: 100%;
    /*height:3px;*/
    margin: 0 auto;
}

.report-pie{
    width: 500px;
}


.clickable-audio-container {
    display: flex;
    align-items: center;
}
.quiz-entry-answer-image-div{
    flex: 1;
}
.quiz-entry-answer-image {
    position: relative;
    right: 7px;
    float: right;
    border-radius: 9px !important;
    width: 130px;
    height: 130px;
    object-fit: cover;
    object-position: center;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 900px) {
    .quiz-entry-answer-image {
        width: 100px !important;
        height: 100px !important;
    }
}



.quiz-entry-icon-sound {
    width: 56px;
    height: 55px;
}


#quizmain {
    font-family: "Segoe UI", Arial, sans-serif;
    width: 100%;
    xxbackground-color: #fff;
}

#qtext {
    font-size: 18px;
}

#quizform {
    margin-top: 40px;
}

#altcontainer {
    background-color: #ffffff00;
    font-size: 120%;
    line-height: 1.7em;
}

#answerbuttoncontainer {
    position: relative;
    padding: 20px 0;
}

.answerbutton {
    background-color: #04AA6D;
    padding: 12px 30px;
    font-size: 17px;
    border-radius: 5px;
}

#timespent {
    position: absolute;
    right: 0;
    text-align: right;
    border: none;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 16px;
    width: 80px;
}

.linktotutdiv {
    color: #555;
    background-color: #FFF4A3;
}

.linktotutdiv a {
    color: #555;
}

.linktotutdiv a:hover, .linktotutdiv a:active {
    color: black;
}

/* The radiocontainer */
.radiocontainer {
    border-radius: 10px;
    background-color: #E7E9EB;
    /*display: block;*/
    position: relative;
    padding: 5px 3px 5px 50px;
    margin-bottom: 1px;
    cursor: pointer;
    font-size: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    word-wrap: break-word;
    font-family: sans-serif;

    margin: 5px;
    min-height: 100px;
    display: flex !important;
    align-items: center;

    /*display: flex;*/
    /*align-items: flex-start; !* Прижимаем содержимое к верху *!*/
}

.quiz-entry-answer-text {
    width: 100%;
    position: relative;
    /*top: -7px;*/
    left: 5px;
    color: #121d4fcc;
    display: flex; /* Располагает дочерние div горизонтально (по умолчанию) */
    gap: 10px; /* Добавляет отступ между div (по желанию) */
}

.quiz-entry-answer-text img {
    align-self: flex-start;
}

.quiz-entry-answer-text {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}



/* Hide the browser's default radio button */
.radiocontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    /*top: 23px;*/
    left: 15px;
    height: 19px;
    width: 19px;
    background-color: #121d4f29;
    border-radius: 50%;
}

.checkedlabel {
    background-color: #ddd;
}

/* On mouse-over, add a grey background color */
.radiocontainer:hover input ~ .checkmark {
    /*nothing*/
}

.radiocontainer:hover {
    background-color: #ddd;
}

/* When the radio button is checked, add a blue background */
.radiocontainer input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radiocontainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radiocontainer .checkmark:after {
    top: 6px;
    left: 6px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
}

body.darkpagetheme .radiocontainer {
    background-color: #38444d;
}

body.darkpagetheme .radiocontainer:hover {
    background-color: #404e59;
}

body.darkpagetheme #timespent {
    background-color: transparent;
    color: #ddd !important;
}

body.darkpagetheme .linktotutdiv {
    color: #ddd;
}

body.darkpagetheme .linktotutdiv a {
    color: #ddd;
}

body.darkpagetheme .linktotutdiv a:hover, body.darkpagetheme .linktotutdiv a:active {
    color: #fff;
}

@media screen and (max-width: 900px) {
    .quizresultbtn {
        display: block;
        width: 100%;
    }
}




.tick {
    color: #37ab00;
}

.cross {
    color: #ab0000;
}

.image-radius {
    right: 10px;
    top: 10px !important;
    position: relative;
    float: right;
    border-radius: 5px !important;
}

.process-entry-result {
    border-radius: 5px !important;
    margin: 10px;
    background-color: #66666624;
    width: 47%;
    height: 74px;
    display: inline-block;
}

.incorrect-result {
    border: 3px /* width */ solid /* style */ magenta; /* color */
}


.correct-selected-result {
    border: 3px /* width */ solid /* style */ #137e00; /* color */
    background-color: #e4ffdf;
}

.incorrect-selected-result {
    border: 3px /* width */ solid /* style */ #7e0000; /* color */
    background-color: #8c0000;
}


.selected-result {
    background-color: #658bff;
}

.selected-incorrect-result {
    border: 3px /* width */ solid /* style */ #7e0000; /* color */
    background-color: #ffc6c6;
}

.unselected-incorrect-result {
    border: 3px /* width */ solid /* style */ #7e0000; /* color */
    background-color: #ffffff;

}

.selected-correct-result {
    border: 3px /* width */ solid /* style */ #157300; /* color */
    background-color: #86ff70;

}

.unselected-correct-result {
    border: 3px /* width */ solid /* style */ #157300; /* color */
    background-color: #ffffff;
}

.result-entry-svg {
    float: left;
    position: relative;
    top: 14px;
    padding-left: 10px;
}




.container-entry-status {

}

.container-entry-status-icon {
    float: left;
    position: relative;
    top: -10px;
}

.result-image-question {
    top: -30px !important;
}

.result-summarize-info {
    margin: 20px;
    padding: 20px;
    /*color: #121D4F;*/
    color: #ffffff;
    font-size: 20px;
    /*background-color: #ffffff;*/
    display: grid;
    gap: 1.5rem;
    border-radius: 7px;
}

/*.result-entry-question-icon-dynamic {*/
/*    position: relative;*/
/*    !*top: 35px;*!*/
/*    !*left: -58px;*!*/
/*}*/

.result-entry-answer-icon-dynamic {
    position: relative;
    top: 35px;
    left: -58px;
}

.result-summarize-info-icon {
    float: left;
    position: relative;
    top: -10px;
}

.pie {
    --w: 200px;

    width: var(--w);
    aspect-ratio: 1;
    position: relative;
    display: inline-grid;
    place-content: center;
    margin: 5px;
    font-size: 25px;
    font-weight: bold;
    font-family: sans-serif;
}

.pie:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    inset: 0;
    background: conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
    mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.result-summarize-info-dia {
    position: absolute;
    left: 66%;
    top: 70px;
}


@property --p {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
}

.pie {
    --p: 20;
    --b: 12px;
    --c: darkred;
    --w: 200px;

    width: var(--w);
    aspect-ratio: 1;
    position: relative;
    display: inline-grid;
    margin: 5px;
    place-content: center;
    font-size: 25px;
    font-weight: bold;
    font-family: sans-serif;
}

.pie:before,
.pie:after {
    content: "";
    position: absolute;
    border-radius: 50%;
}

.pie:before {
    inset: 0;
    background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
    mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.pie:after {
    inset: calc(50% - var(--b) / 2);
    background: var(--c);
    transform: rotate(calc(var(--p) * 3.6deg)) translateY(calc(50% - var(--w) / 2));
}

.animate {
    animation: p 1s .5s both;
}

.no-round:before {
    background-size: 0 0, auto;
}

.no-round:after {
    content: none;
}

@keyframes p {
    from {
        --p: 0
    }
}

body {
    background: #ddd;
    font-family: sans-serif;
}


/*#quizmain {*/
/*    font-family: "Segoe UI", Arial, sans-serif;*/
/*    width: 100%;*/
/*    background-color: #fff;*/
/*}*/

/*#qtext {*/
/*    font-size: 18px;*/
/*}*/

/*#quizform {*/
/*    margin-top: 40px;*/
/*}*/

/*#altcontainer {*/
/*    background-color: #fff;*/
/*    font-size: 120%;*/
/*    line-height: 1.7em;*/
/*}*/

/*#answerbuttoncontainer {*/
/*    position: relative;*/
/*    padding: 20px 0;*/
/*}*/

/*.answerbutton {*/
/*    background-color: #04AA6D;*/
/*    padding: 12px 30px !important;*/
/*    font-size: 17px;*/
/*    border-radius: 5px;*/
/*}*/

/*#timespent {*/
/*    position: absolute;*/
/*    right: 0;*/
/*    text-align: right;*/
/*    border: none;*/
/*    font-family: "Segoe UI", Arial, sans-serif;*/
/*    font-size: 16px;*/
/*    width: 80px;*/
/*}*/

/*.linktotutdiv {*/
/*    color: #555;*/
/*    background-color: #FFF4A3;*/
/*}*/

/*.linktotutdiv a {*/
/*    color: #555;*/
/*}*/

/*.linktotutdiv a:hover, .linktotutdiv a:active {*/
/*    color: black;*/
/*}*/

/*!* The radiocontainer *!*/
/*.radiocontainer {*/
/*    background-color: #E7E9EB;*/
/*    display: block;*/
/*    position: relative;*/
/*    padding: 23px 10px 10px 50px;*/
/*    margin-bottom: 1px;*/
/*    cursor: pointer;*/
/*    font-size: 20px;*/
/*    -webkit-user-select: none;*/
/*    -moz-user-select: none;*/
/*    -ms-user-select: none;*/
/*    user-select: none;*/
/*    word-wrap: break-word;*/
/*}*/

/*!* Hide the browser's default radio button *!*/
/*.radiocontainer input {*/
/*    position: absolute;*/
/*    opacity: 0;*/
/*    cursor: pointer;*/
/*}*/

/*!* Create a custom radio button *!*/
/*.checkmark {*/
/*    position: absolute;*/
/*    top: 15px;*/
/*    left: 15px;*/
/*    height: 19px;*/
/*    width: 19px;*/
/*    background-color: #fff;*/
/*    border-radius: 50%;*/
/*}*/

/*.checkedlabel {*/
/*    background-color: #ddd;*/
/*}*/

/*!* On mouse-over, add a grey background color *!*/
/*.radiocontainer:hover input ~ .checkmark {*/
/*    !*nothing*!*/
/*}*/

/*.radiocontainer:hover {*/
/*    background-color: #ddd;*/
/*}*/

/*!* When the radio button is checked, add a blue background *!*/
/*.radiocontainer input:checked ~ .checkmark {*/
/*    background-color: #2196F3;*/
/*}*/

/*!* Create the indicator (the dot/circle - hidden when not checked) *!*/
/*.checkmark:after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    display: none;*/
/*}*/

/*!* Show the indicator (dot/circle) when checked *!*/
/*.radiocontainer input:checked ~ .checkmark:after {*/
/*    display: block;*/
/*}*/

/*!* Style the indicator (dot/circle) *!*/
/*.radiocontainer .checkmark:after {*/
/*    top: 6px;*/
/*    left: 6px;*/
/*    width: 7px;*/
/*    height: 7px;*/
/*    border-radius: 50%;*/
/*    background: white;*/
/*}*/

/*body.darkpagetheme .radiocontainer {*/
/*    background-color: #38444d;*/
/*}*/

/*body.darkpagetheme .radiocontainer:hover {*/
/*    background-color: #404e59;*/
/*}*/

/*body.darkpagetheme #timespent {*/
/*    background-color: transparent;*/
/*    color: #ddd !important;*/
/*}*/

/*body.darkpagetheme .linktotutdiv {*/
/*    color: #ddd;*/
/*}*/

/*body.darkpagetheme .linktotutdiv a {*/
/*    color: #ddd;*/
/*}*/

/*body.darkpagetheme .linktotutdiv a:hover, body.darkpagetheme .linktotutdiv a:active {*/
/*    color: #fff;*/
/*}*/

/*@media screen and (max-width: 600px) {*/
/*    .quizresultbtn {*/
/*        display: block;*/
/*        width: 100%;*/
/*    }*/
/*}*/

/*    #cboxWrapper{
        height: 200px !important;
        width: 200px !important;
    }*/


/*.orange > span {*/
/*    background-image: linear-gradient(#f1a165, #f36d0a);*/
/*}*/

/*.nostripes > span > span,*/
/*.nostripes > span::after {*/
/*    background-image: none;*/
/*}*/

.meter {
    /*box-sizing: content-box;*/
    /*height: 20px; !* Can be anything *!*/
    /*position: relative;*/
    /*margin: 60px 0 20px 0; !* Just for demo spacing *!*/
    /*background: #555;*/
    /*border-radius: 25px;*/
    /*padding: 10px;*/
    /*box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);*/
}

.meter > span {
    display: block;
    height: 10px;
    /*border-top-right-radius: 8px;*/
    /*border-bottom-right-radius: 8px;*/
    /*border-top-left-radius: 20px;*/
    /*border-bottom-left-radius: 20px;*/
    background-color: rgb(43, 194, 83);
    /*background-image: linear-gradient(*/
    /*        center bottom,*/
    /*        rgb(43, 194, 83) 37%,*/
    /*        rgb(84, 240, 84) 69%*/
    /*);*/
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;
}

.meter > span:after,
.animate > span > span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /*background-image: linear-gradient(*/
    /*        -45deg,*/
    /*        rgba(255, 255, 255, 0.2) 25%,*/
    /*        transparent 25%,*/
    /*        transparent 50%,*/
    /*        rgba(255, 255, 255, 0.2) 50%,*/
    /*        rgba(255, 255, 255, 0.2) 75%,*/
    /*        transparent 75%,*/
    /*        transparent*/
    /*);*/
    z-index: 1;
    background-size: 50px 50px;
    animation: move 2s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
}

.quiz-list {
    color: #ffffff;
    /*font-size: 25px;*/
    padding: 5px;
}

@media (max-width: 900px) {
    .quiz-list{
        padding: 0px !important;
    }
}


.quiz-list-link {
    color: #ffffff;
}

.quiz-entry-result-success {
    background-color: rgba(73, 255, 83, 0.5);
    padding: 20px 10px 10px 10px;
    margin-left: 20%;
    margin-right: 20%;
    /*height: 70px;*/
    x: center;
    border-radius: 10px;
    position: absolute;
    width: 400px;
    top: 0px;
    z-index: 1;
}

.quiz-cancel-button{
    float: right;
}

.quiz-entry-process-result{
    display: flex;
    justify-content: center;
    width: 100%;
}


.quiz-entry-result-entry-svg {
    float: left;
    position: relative;
    top: -5px;
    padding-left: 10px;
}

.quiz-entry-result-answer-text {
    float: left;
    position: relative;
    padding-left: 7px;
    width: 100%;
    font-size: 15px;

}

.quiz-entry-result-answer-image {
    top: 0px !important;
    float: right;
    border-radius: 8px !important;
    top: 10px;
    right: 10px;
    margin: 5px;
    padding: 2px;
    width: 110px;
    height: 110px;
    object-fit: cover;
    object-position: center;
}

.quiz-selected-incorrect-result {
    border: 3px solid #d1006c;
    background-color: #ffb3cb !important;
}

.quiz-unselected-correct-result {
    /*border: 3px !* width *! solid !* style *! #157300; !* color *!*/
    /*background-color: #ffffff;*/
    border: 3px /* width */ solid /* style */ #157300;
    background-color: #86ff70 !important;
}

.quiz-result-container-entry {
    margin: 0px 20px 20px 20px;
    padding: 0px 20px 20px 20px;
    color: #121D4F;
    font-size: 20px;
    /*background-color: #ffffff;*/
    display: grid;
    gap: 1.5rem;
    /*grid-template-columns: 1fr 1fr;*/
    border-radius: 7px;
}

.quiz-result-container-entry-inner-header {
    margin-top: 20px;
    margin-left: -20px;
    font-size: 30px;
    color: #99227b;
    /*font-weight: 600;*/
}





.quiz-entry-question-text {
    font-size: 40px;
    font-family: "Segoe UI", Arial, sans-serif;
}

.quiz-entry-question-image {
    border-radius: 5px;
}

.top-menu {
    padding-top: 10px;
    padding-bottom: 15px;
    background-color: #00b2ff2b;
    padding-left: 10px;
    box-shadow: inset 0 2px 9px rgb(255 255 255 / 20%), inset 0 -2px 6px rgb(0 0 0 / 20%);
}

.top-menu-link {
    color: #ffa9f0b3;
    text-decoration: none;
    padding-right: 20px;
    font-size: 20px;
    font-family: sans-serif;

}

.quiz-entry-top-info {
    padding-top: 10px;
    padding-left: 7px;
}

.unselect-text {
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

#fixing-exit-from-page {
    height: 3px;
    background-color: #121d4f;
}

.quiz-cancel {
    color: #ffa9f0b3;
    text-decoration: none;
    padding-right: 20px;
    font-size: 15px;
    font-family: sans-serif;
}

.table-data {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.table-data td, .table-data th {
    border: 1px solid #18245b;
    padding: 8px;
}

.table-data tr:nth-child(even) {
    background-color: #4b5791;
}

.table-data tr:hover {
    background-color: #4b5791;
}



.table-data-icon {
    color: #1976d2;
}
.table-data-icon:hover {
    color: #f557de !important;
}

.table-data-icon button{
    color: #1976d2;
}
.table-data-icon:hover button{
    color: #f557de !important;
}


.finance-table td:nth-child(1){
    width: 10px;
    text-align: center;
    font-size: 10px;
}
.finance-table td:nth-child(2){
    text-align: left;
    font-size: 10px;
}
.finance-table td:nth-child(3){
    width: 10px;
    text-align: center;
    font-size: 10px;
}

.quiz-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.quiz-table th:nth-child(1),
.quiz-table th:nth-child(3),
.quiz-table th:nth-child(4) {
    width: 10px;
    text-align: center;
}


.quiz-table td, .quiz-table th {
    border: 1px solid #18245b;
    padding: 8px;
}

.quiz-table tr:nth-child(even) {
    background-color: #4b5791;
}

.quiz-table tr:hover {
    background-color: #4b5791;
}

.quiz-list-link {
    color: #a5a5a5;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.quiz-list-link:hover {
    transform: scale(1.1);
}

.text-link {
    color: #ffa9f0b3;
    text-decoration: none;
    font-size: 18px;
    font-family: sans-serif;
}

.quiz-list-table-min {
    width: 5px;
    text-align: center;
}

#quiz-text-field {
    width: 98%;
    border-radius: 10px;
    height: 70px;
    font-size: 25px;
    padding-left: 20px;
}

.quiz-entry-question-imaged-text {
    max-width: 80%;
    height: auto;
}

.quiz-entry-answer-image-link {
    max-width: 30%;
}

body {
    background-color: rgb(18, 29, 79) !important;
    color: rgb(255, 255, 255) !important;
    margin: 0 !important;
    /*min-height: 800px;*/
}


.pie {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: conic-gradient(var(--c) var(--p), #ddd 0);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
}

.animate {
    animation: fillPie 2s ease-out forwards;
}

@keyframes fillPie {
    from {
        background: conic-gradient(#ddd 0, #ddd 0);
    }
    to {
        background: conic-gradient(var(--c) var(--p), #ddd 0);
    }
}

.no-round {
    border-radius: 0;
}



.quizzes-number-icon-tooltip {
    display: inline-flex;
    align-items: center;
}

.quizzes-number-icon {
    width: 17px;
    height: 17px;
    border-radius: 2px;
    background-color: #d5dcff5e;
    margin-right: 4px;
    display: flex;
    justify-content: center; /* Выравнивание по горизонтали по центру */
    align-items: center; /* Выравнивание по вертикали по центру */
}

.quizzes-number-icon-number {
    color: #18181b;
    font-weight: bold;
    position: absolute;
    text-align: center;
    font-size: 12px;
    padding-left: 5px;
    padding-top: 2px;
    font-family: auto;
}

.report-chart{
    width: 100% !important;
}
.report-chart-dates{
    margin: 20px;
    display: inline-flex;
}
.report-chart-date{
    width: 100px !important;
}

.menu-items {
    display: none;
}

.menu-items.open {
    display: block;
}


.menu-toggle {
    cursor: pointer;
}

.menu-toggle.open {
}

.top-menu {
}

.menu-items {
}

.menu-items.open {
}

@media (min-width: 768px) {
    .menu-items {
        display: flex;
    }
}



.chidlren-list-button{
    display: inline-flex;
}
.chidlren-list-button button{
    margin: 3px;
}

.modalConfirmDelete {
    z-index: 1001 !important; /* Установите значение z-index по вашему усмотрению */
}
h1{
    font-size: 20px !important;
}
