@media (max-width: 900px) {
    .quiz-entry-result-mistake {
        left: 3px !important;
        top: 42px !important;
        margin: 0px !important;
        padding: 0px !important;
        max-width: 100% !important;
    }
}

@media (min-width: 900px) {
    .quiz-entry-result-mistake {
        /*left: 14%;*/
        max-width: 64% ;
    }
}

.quiz-entry-result-mistake {
    position: absolute;
    top: 143px;
    z-index: 1;
    margin: 0px 20px 20px 20px;
    padding: 0px 20px 20px 20px;
    color: #121D4F;
    font-size: 20px;
    background-color: #ffe4f6d9;
    box-shadow: inset 0 100px 50px rgba(255, 255, 255, 0.3), inset 0 -100px 300px rgb(137 48 142 / 74%);
    display: grid;
    gap: 1.5rem;
    border-radius: 15px;
}



.quiz-entry-result-mistake-entry {
    border-radius: 7px !important;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    margin-top: 15px;
    background-color: #ffe4f6ed;
    /*height: -webkit-fill-available;*/
    display: flow-root;
}

.quiz-entry-result-mistake-text {
    position: relative;
    top: 11px;
    font-size: 30px !important;
    font-family: auto;
    font: status-bar;
    left: 110px;
}

.quiz-entry-result-mistake-smile {
    position: relative;
    top: -24px;
    left: 53px;
}

.quiz-entry-result-mistake-smile-svg {
    color: #ffea51;
}


.quiz-entry-result-success-text {
    position: relative;
    top: 11px;
    font-size: 30px !important;
    font-family: auto;
    font: status-bar;
    left: 134px;
}

.quiz-entry-result-success-smile {
    position: relative;
    top: -24px;
    left: 77px;
}

.quiz-entry-result-success-smile-svg {
    color: #ffea51;
}


